
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');

:root {
  --primary-white: #fff;
  --primary-orange: #f9ab00;
  --primary-dark: rgb(52, 58, 64);
  --primary-other: rgb(0, 0, 0);
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;
  --primary-light-grey: #f4f4f4;
  --primary-black: #000000;
  --primary-green: green;
  --primary-grey: #ccd1d9;
  --primary-dark-grey: #666666;
  --primary-blue: #899ec5;
  --primary-alt-grey:#e4e4e4;
}


body {
  font-family: "Roboto Condensed", sans-serif !important;
}

/* Navbar Component Styling Below */

nav {
  z-index: 2;
}

.logo {
  width: 16.25rem;
}

nav a.nav-link {
  font-size: .8rem;
  letter-spacing: 0.0375rem;
  cursor: pointer;
  
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: var(--primary-white) !important;
  border-top: 0.1875rem solid var(--primary-dark);
}

.navbar-brand {
  max-width: 17.25rem;
  padding: 0 !important;
  height: 6rem !important;
}

@media(max-width: 768px) {
    .navbar-brand {
      width:7rem;
    }

    .navbar-nav > li > a.active, .navbar-nav > li > a:focus {
      border-top: 0.2875rem solid var(--primary-orange);
    }
  }

.navbar-nav > li > a.active, .navbar-nav > li > a:focus {
  border-top: 0.1875rem solid var(--primary-orange);
}

.navbar-nav > li > a:hover {
  border-top: 0.1875rem solid var(--primary-orange);
}

/* Header Component Styling Below */

.header-wraper {
  position: relative;
  background: url(b.JPG) no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.main-info {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
}

.main-info h1 {
  color: var(--primary-white);
  text-transform: uppercase;
  padding-top: 8rem;
}



/* Typed Text Effect Styling Below */


.typed-text {
  font-size: 2rem;
  color: var(--primary-white)
}

@media(max-width: 768px) {
  .main-info h1 {
    font-size: 1.7rem !important;
    
  }
  .typed-text {
    font-size: 1.3rem !important;
  }
  }


/* Header main ofr btn styling below */

.btn-main-offer {
  border: 1px solid var(--primary-red);
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem  0.75rem ;
  color: var(--primary-white);
  margin: 2rem;
}

.btn-main-offer:hover {
  text-decoration: none;
  background-color: var(--primary-hover-red);
  color: var(--primary-white);
  transition:  .2s ease-in-out;
  cursor: pointer;
}

.tsparticles-canvas-el {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}

@media (max-width: 768px) {
  .particles-canvas {
    display: none;
  }
}

/* About Me Component Styling Below */

.photo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-img {
  width: 16rem;
  border-radius: 50%;
  padding: .3rem;
  border:1px solid var(--primary-orange);
}

.about-heading {
  color: var(--primary-orange);
  text-transform: uppercase;
}


@media(max-width: 468px) {
  .profile-img {
    width: 11rem;
  }
}

/* Services Component Styling Below */

.services {
  text-align: center;
  padding-bottom: 2rem;
  background-color: var(--primary-light-grey);
}

.services h1 {
  color: var(--primary-dark);
  text-transform: uppercase;
}

.services .circle {
  position: relative;
  margin: 0.375rem auto;
  background: var(--primary-orange);
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.services .icon {
  font-size: 1.4rem;
  color: var(--primary-white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.services .box {
  height: 16rem;
  border-bottom: 0.3125rem solid var(--primary-orange);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: var(--primary-white);
  transition: 0.3s ease-in-out;
}

.services .box:hover {
  background: var(--primary-orange);
  border-bottom: 0.3125rem solid var(--primary-dark);
}

.services .box:hover .icon {
  color: var(--primary-dark);
}

.services .box:hover .circle {
  background: var(--primary-white);
}

/* Portfolio Component Styling Below */
.portfolio-wrapper {
  background: var(--primary-alt-grey);
  padding: 3rem 0;
}

.portfolio-wrapper h1 {
  color: var(--primary-orange);
}

.portfolio-image {
  width: 18rem;
  height: 12rem;
  border: 1px solid var(--primary-orange);
  padding: 0 0.5;
}

.portfolio-image-box {
  position: relative;
  margin: 1rem;
}

.image-box-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.popupbox {
      height: 100vh;
      padding-top: 6rem;
}

.popupbox-content {
  padding: 20px !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 35vw;
}

.portfolio-image-popupbox {
  margin: none;
  width: 35rem;
  padding: 0 !important;
  justify-content: center;
  align-self: center;
  margin-bottom: 20px;
  border: 1px solid #bfbfcc;
}

.popupbox-content p {
  padding: 10px;
  border-radius: 10px;
  font-weight: 800;
  color: #393939;
  padding-bottom: 20px;
}

/* .popupbox-content p { */
  /* display: flex;
  justify-content: center;
  flex-direction: column; */
  /* margin-top: -20px; */
  /* padding: 30px;
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 0;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  background-color: var(--primary-orange);
  border-radius: 10px;
} */

.popup-contents {
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* margin-top: -20px; */
  padding: 30px;
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 0;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  background-color: var(--primary-orange);
  border-radius: 10px;
}

/* overflow styling below */

.overflow {
  position: absolute;
  opacity: 0;
  background: var(--primary-black);
  width: 18rem;
  height: 12rem;
  top: 0;
  border: 1px solid var(--primary-red);
  cursor: pointer;
}

.portfolio-image-box:hover .overflow {
  opacity: 60%;
  transition: 0.3s ease-in-out;
}

.portfolio-icon {
  position: absolute;
  opacity: 0;
  margin-left: -10rem;
  margin-top: 4.7rem;
  color: var(--primary-white);
  font-size: 1.5rem;
}

.portfolio-image-box:hover .portfolio-icon {
  opacity: 70%;
  transition: 0.3s ease-in-out;
}

.popupbox-wrapper {
  max-width: 70% !important;
}

.linked-section {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  padding: 20px;
  margin-top: -40px;
}

/* overflow box styling */

.portfolio-image-popupbox {
  margin: none;
  justify-content: center;
  align-self: center;
  margin: none;
  padding: 0 !important;
  justify-content: center;
  align-self: center;
  margin-bottom: 20px;
  border: 1px solid #bfbfcc;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  max-width: 30rem;
}

.art-img {
  padding-bottom: 15px;
}

.hyper-link {
  cursor: pointer;
  color: var(--primary-orange);
}

.hyper-link:hover {
  cursor: pointer;
  color: var(--primary-red);
}

/* Contact Component Styling */

.contacts {
  background: var(--primary-dark);
  padding: 3rem 0;
}

.contacts h1 {
  color: var(--primary-orange);
  padding: 1.3rem 0;
  text-transform: uppercase;
}

.contacts p {
  color: var(--primary-light-grey);
  font-size: 1.1rem;
}

/* INPUTS FOR CONTACT ME */

.contacts input, .contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 0.125rem solid var(--primary-dark-grey);
  border-radius: 0;
  color: var(--primary-orange);
  outline: 0 !important;
  box-shadow: none !important;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.contacts .container {
  padding: 4rem 0;
}

.contacts input:focus, .contacts textarea:focus {
  background: transparent;
  color: var(--primary-orange);
  border-bottom: 0.125rem solid var(--primary-dark-grey);
}

.contacts textarea {
  height: 16.2rem !important;
}

.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;
}

.line {
  position: relative;
  top: -0.8rem;
  width: 0;
  height: 0.125rem;
  background: var(--primary-orange);
  display: inline-block;
}

input:focus+.line, textarea:focus+.line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input.form-control {
  margin: -0.3rem 0;
}

textarea.form-control {
  margin: -0.3rem 0;
}

.error-message {
  color: var(--primary-white);
  background: var(--primary-blue);
  text-align: left;
}

.success-message {
  font-weight: 900;
  color: var(--primary-green);
}
/* Media Queries for Contact Inputs */

@media(max-width: 768px) {
  .contacts {
    overflow-x: hidden;
  }
  
  .contacts .container {
    padding: 0 1rem;
  }

  .contacts p {
    padding: 0 0.4rem;
  }
}

/* Styling for Footer Component */

.socialMedia{
  justify-content: space-evenly;
}

.footer {
  background: var(--primary-black);
  color:var(--primary-dark-grey);
  padding: 3rem;
}

.footer a {
  text-decoration: none;
  color: var(--primary-dark-grey);
}

.footer a:hover {
  text-decoration: none;
  color: var(--primary-orange);
  cursor: pointer;
}

.footer p {
  margin-bottom: 0;
}

.linked-section {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  padding: 12px;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: -30px;
}

.content {
  border: 1px solid #83838370;
  padding: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 20px;
  background-color: #fef8ec78;
}

/* Media Queries for project imgs */

@media(max-width: 1500px) {
  .portfolio-image-popupbox {
    min-width: 60%;
    max-width: 100%;
    height: 55%;
  }
  
  .popupbox-wrapper {
    height: 80%;
  }
  }

@media(max-width: 768px) {
.portfolio-image-popupbox {
  width: 75%;
}

.popupbox-wrapper {
  height: 80%;
}
}

@media only screen and (max-width: 600px) {
  .portfolio-image-popupbox {
    width: 100% !important;
  }
  .popupbox-content {
    width: auto !important;
    overflow-y: scroll !important;
  }
}

@media(max-width: 320px) {
  .portfolio-image-popupbox {
    min-width: 300px;
  }
  .popup-contents {
    width: auto !important;
    font-size: 15px;
  }
  
  .popupbox-wrapper {
    height: 70vh !important;
    
  }
  
  .popupbox-content {
    font-size: small;
  }
  
  .popupbox-wrapper a {
    font-size: x-small;
  }

  .popupbox-wrapper p {
    font-size:x-small !important;
    margin: 0;
  }

  .popupbox-content img {
    width: 70%;
    height: 50%;
  }
  }



@media(max-width: 415px) {
  .portfolio-image-popupbox {
    width: 80%;
    height: 50%;
  }
  
  .popupbox-wrapper {
    height: 50vh !important;
    flex: none;
    
  }
  
  .popupbox-content {
    font-size: small;
  }
  
  .popupbox-wrapper a {
    font-size: x-small;
  }

  .popupbox-wrapper p {
    font-size:9.5px;
  }
  }

@media(max-height: 570px) {

  .popupbox-wrapper {
    height: 100%;
  }

  .portfolio-image-popupbox {
    width: 50%;
  }
  }


  @media(max-height: 280px) {

    .popupbox-wrapper {
      height: 90%;
    }
  
    .portfolio-image-popupbox {
      width: 30%;
    }
    }